.main-content {
    width: 100%;
}

.container {
    display: flex;
    overflow-y: clip;
    overflow-x: clip;
    margin-right: 32px;
}

@media (max-width: 800px) {
    .main-content {
        width: 100%;
        margin: 50px;
    }

    .container {
        margin-right: 24px;
    }

    .dashboard-drawer {
        display: block;
    }

    .appbarcontent {
        display: none;
    }

    .appbarspace {
        display: block;
    }
}

@media (min-width: 801px) and (max-width: 750px) {
    .main-content {
        width: 100%;
        margin: 50px;
    }

    .container {
        margin-right: 24px;
    }

    .dashboard-drawer {
        display: block;
    }

    .appbarspace {
        display: block;
    }

    .appbarcontent {
        display: none;
    }
}

@media (min-width: 751px) {
    .main-content {
        width: 100%;
        margin: 20px;
    }

    .container {
        margin-right: 16px;
    }

    .dashboard-drawer {
        display: none;
    }

    .appbarspace {
        display: none;
    }

    .appbarcontent {
        display: block;
    }
}

@media (max-width: 700px) {
    .main-content {
        width: 100%;
        margin: 10px;
    }

    .container {
        margin-right: 8px;
    }
}

@media (max-width: 600px) {
    .main-content {
        width: 100%;
        margin: 5px;
    }

    .container {
        margin-right: 4px;
    }

    .table-full {
        display: none;
    }
}

@media (max-width: 400px) {
    .main-content {
        width: 100%;
        margin: 0;
    }

    .container {
        margin-right: 0;
    }

    .table-full {
        display: none;
    }
}

@media (min-width: 600px) {
    .table-compact {
        display: none;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.truncateWrap {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 901px) {
    .dashboard-drawer {
        display: none;
    }

    .appbarspace {
        display: none;
    }

    .appbarcontent {
        display: block;
    }
}
